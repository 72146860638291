<template>
  <div>
        <router-link :to="{name: 'RecipesAdmin'}">Volver</router-link>
        <button @click="next()">Next</button>
        <button @click="prev()">Prev</button>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">Id</span>
            <span class="fieldValue">{{recipe.id}}</span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">routerLink</span>
            <span class="fieldValue"><el-input v-model="recipe.routerLink" type="text"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">name</span>
            <span class="fieldValue"><el-input v-model="recipe.name" type="text"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">shortName</span>
            <span class="fieldValue"><el-input v-model="recipe.shortName" type="text"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">category</span>
            <span class="fieldValue"><el-input v-model="recipe.category" type="text"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">type</span>
            <span class="fieldValue"><el-input v-model="recipe.type" type="text"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">youtubeUrl</span>
            <span class="fieldValue"><el-input v-model="recipe.youtubeUrl" type="text"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">ingredients</span>
            <span class="fieldValue"><el-input type="textarea" :autosize="{ minRows: 10, maxRows: 15 }" v-model="recipe.ingredients"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">fullHtml</span>
            <span class="fieldValue"><el-input type="textarea" :autosize="{ minRows: 15, maxRows: 100 }" v-model="recipe.fullHtml"/></span>
        </div>

        <iframe v-if="recipe.youtubeUrl" width="560" height="315" :src="'https://www.youtube.com/embed/' + recipe.youtubeUrl"></iframe>
        <div v-if="!recipe.youtubeUrl" style="font-size: 20px;">No Subido a Youtube</div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">procedureSteps</span>
            <span class="fieldValue"><el-input type="textarea" :autosize="{ minRows: 15, maxRows: 25 }" v-model="recipe.procedureSteps"/></span>
        </div>

        <div class="recipeFieldDiv">
            <span class="fieldLabel">introText</span>
            <span class="fieldValue"><el-input type="textarea" :autosize="{ minRows: 15, maxRows: 25 }" v-model="recipe.introText"/></span>
        </div>

        <button @click="saveAndBack()">Guardar y Volver</button>
        <button @click="saveAndNext()">Guardar y Siguiente</button>
        <button @click="save()">Guardar</button>
        <button @click="next()">Next</button>
        <button @click="prev()">Prev</button>
        {{savedStatus}}
  </div>
</template>

<script>
import router from "@/router";

export default {
    name: "RecipeEdition",
    props: [
        'id',
    ],
    beforeCreate() {},
    created: function () {
       this.loadRecipe();
    },
    data() {
        return {
            recipe: null,
            savedStatus: null
        };
    },
    methods: {
        loadRecipe() {
            this.savedStatus = "";
            this.$store.dispatch("main/retrieveRecipeById", this.id).then((response) => {
                this.recipe = response;
            });
        },
        save() {
            this.savedStatus = "";
            this.$store.dispatch("main/saveRecipe", this.recipe).then((response) => {
                this.savedStatus = response ? "GUARDADO" : "ERROR";
            });
        },
        saveAndBack() {
            this.savedStatus = "";
             this.$store.dispatch("main/saveRecipe", this.recipe).then((response) => {
                this.savedStatus = response ? "GUARDADO" : "ERROR";
                if (response) {
                    router.push("/");
                }
            });
        },
        saveAndNext() {
            this.savedStatus = "";
             this.$store.dispatch("main/saveRecipe", this.recipe).then((response) => {
                this.savedStatus = response ? "GUARDADO" : "ERROR";
                if (response) {
                    this.next();
                }
            });
        },
        next() {
            router.push("/recipe/" + (parseInt(this.id) + 1));
        },
        prev() {
            router.push("/recipe/" + (parseInt(this.id) - 1));
        }
    },
    watch: {
        id() {
            this.loadRecipe();
        }
    }
};
</script>

<style>
.recipeFieldDiv {
    display: flex;
    flex-direction: column;
}
.recipeFieldDiv .fieldLabel {
    font-weight: bold;
    margin-top: 5px;
}
.recipeFieldDiv .el-textarea__inner {
    min-width: 1000px;
}
.recipeFieldDiv .el-input__inner {
    min-width: 1000px;
}
</style>