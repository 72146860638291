<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "app",
  beforeCreate() {},
  created: function () {
   
  },
  data() {
    return {
      
    };
  },
  methods: {
   
  },
};
</script>

<style>
@import "styles/global.scss";

</style>
