import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import CategoriesAdmin from '@/views/CategoriesAdmin.vue'
import ArticlesAdmin from '@/views/ArticlesAdmin.vue'
import RecipeEdition from '@/views/RecipeEdition.vue'
import RecipesAdmin from '@/views/RecipesAdmin.vue'
import CategoryEdition from '@/views/CategoryEdition.vue'
import ArticleEdition from '@/views/ArticleEdition.vue'
import RecipesDataEntryAdmin from '@/views/RecipesDataEntryAdmin.vue'
import RecipeDataEntryEdition from '@/views/RecipeDataEntryEdition.vue'

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: "/",
      name: 'Home',
      component: Home,
    },
    {
      path: "/login",
      name: 'Login',
      component: Login,
    },
    {
      path: "/recipes",
      name: 'RecipesAdmin',
      component: RecipesAdmin,
    },
    {
      path: "/categories",
      name: 'Categorias',
      component: CategoriesAdmin,
    },
    {
      path: "/articles",
      name: 'Articulos',
      component: ArticlesAdmin,
    },
    {
      path: "/recipe/:id",
      name: 'RecipeEdition',
      component: RecipeEdition,
      props:true,
    },
    {
      path: "/category/:id",
      name: 'CategoryEdition',
      component: CategoryEdition,
      props:true,
    },
    {
      path: "/article/:id",
      name: 'ArticleEdition',
      component: ArticleEdition,
      props:true,
    },
    {
      path: "/recipesDataEntryAdmin",
      name: 'RecipesDataEntryAdmin',
      component: RecipesDataEntryAdmin,
      props:true,
    },
    {
      path: "/recipeDataEntryEdition/:id",
      name: 'RecipeDataEntryEdition',
      component: RecipeDataEntryEdition,
      props:true,
    },
  ]
})

router.beforeEach((to, from, next) => {
  var VueCookie = require('vue-cookie');
  var cookieUser = VueCookie.get('authentication');

  if (cookieUser || to.name == 'Login') {
    if (to.name == 'Home') {
      next({ name: 'RecipesDataEntryAdmin' });
    } else {
      next();
    }
  } else {
    next({ name: 'Login' });
  }
})

export default router;
