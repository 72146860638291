<template>
  <div id="app" class="table" style="background-color: #e5e9ec">
    <div class="searchFilters">
      <el-checkbox v-model="noStepsLoaded">No cargados</el-checkbox>
    </div>
    <div class="statsDiv" v-if="stats">
      Pasos Cargados {{stats.stepsWithFramesNotNull}} / Pasos Totales {{stats.stepsWithFramesNull + stats.stepsWithFramesNotNull}}
    </div>
    <div class="manualDiv">
      <a href="https://docs.google.com/document/d/18ewdrPFcg211YKh3Qnhe8RmimZMFWlcSrnjZFaNuo0E/edit?usp=sharing" target="_blank">Ver Manual</a>
    </div>

    <el-table v-bind:data="recipes" stripe class="table table-striped">
        <el-table-column label="id" prop="recipeId" width="30"></el-table-column>
        <el-table-column label="name" prop="name" width="370"></el-table-column>
        <el-table-column label="Pasos Cargados" width="120">
            <template slot-scope="scope">
                {{scope.row.stepsAllCompleted ? "SI" : "NO"}}
            </template>
        </el-table-column>

        <el-table-column width="70">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="fas fa-edit"
              class="recipesDataEntryButton"
              v-on:click="editRecipe(scope.row)"
            ></el-button>
             <el-button
              type="text"
              size="small"
              icon="fas fa-external-link-square-alt"
              class="recipesDataEntryButton"
              v-on:click="goToRecipe(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify"; // eslint-disable-line no-unused-vars
import router from "@/router";

export default {
  name: "RecipesDataEntryAdmin",
  beforeCreate() {
    
  },
  created: function () {
    var noStepsLoadedCookie = this.$cookies.get("noStepsLoaded");
    if (noStepsLoadedCookie == "true") {
      this.noStepsLoaded = true;
    } else {
      this.noStepsLoaded = false;
    }
   
    this.$store.dispatch("main/retrieveAllDataEntryRecipes").then((response) => {
      this.recipesFull = response;
      this.updateRecipesList();
    });

    this.$store.dispatch("main/getDataEntryStats").then((response) => {
      this.stats = response;
    });
  },
  data() {
    return {
      recipesFull: null,
      recipes: null,
      noStepsLoaded: false,
      stats: null
    };
  },
  methods: {
    editRecipe(entry) {
      router.push("/recipeDataEntryEdition/" + entry.recipeId);
    },
    goToRecipe(entry) {
      var url = 'https://fedecocina.net/receta/' + entry.pageUrl;
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    updateRecipesList() {
      if (!this.recipesFull) {
        return;
      }

      var final = [];
      for (var i = 0; i < this.recipesFull.length; i++) {
        var current = this.recipesFull[i];
        if (this.noStepsLoaded) {
          if (!current.stepsAllCompleted) {
            final.push(current);
          }
        } else {
          final.push(current);
        }
      }

      this.recipes = final;
    },
    hasCustomIntro(intro) {
      if (intro) {
        return intro.toLowerCase().startsWith('aprende como');
      }
      return true;
    }
  },
  watch: {
    noStepsLoaded() {
      this.$cookies.set("noStepsLoaded", this.noStepsLoaded);
      this.updateRecipesList();
    }
  }
};
</script>

<style>
  .searchFilters {
    margin-bottom: 4px;
  }
  .statsDiv {
    margin-bottom: 4px;
  }
  .manualDiv {
    margin-bottom: 10px;
  }
  .recipesDataEntryButton {
    margin-right: 5px;
  }
</style>
