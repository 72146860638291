<template>
  <div id="app" class="table" style="background-color: #e5e9ec">
    <div class="searchFilters">
      <button @click="goToCategories()">Categories</button>
      <button @click="goToArticles()">Articles</button>
      <el-checkbox v-model="noIngredients">Sin Ingredientes</el-checkbox>
      <el-checkbox v-model="noProcedure">Sin Procedimiento</el-checkbox>
      <el-checkbox v-model="onYoutube">Subido a Youtube</el-checkbox>
      <el-checkbox v-model="noFullHtml">Sin Full Html</el-checkbox>
    </div>

    <el-table v-bind:data="recipes" stripe class="table table-striped">
        <el-table-column label="id" prop="id" width="30"></el-table-column>
        <el-table-column label="routerLink" prop="routerLink" width="100"></el-table-column>
        <el-table-column label="name" prop="name" width="370"></el-table-column>
        <el-table-column label="shortName" prop="shortName" width="300"></el-table-column>
        <el-table-column label="category" prop="category" width="120"></el-table-column>
        <el-table-column label="type" prop="type" width="60"></el-table-column>
        <el-table-column label="yield" prop="recipeYield" width="60"></el-table-column>
        <el-table-column label="yieldGrams" prop="recipeYieldGrams" width="90"></el-table-column>
        <el-table-column label="yieldType" prop="recipeYieldType" width="90"></el-table-column>
        <el-table-column label="youtubeUrl" prop="youtubeUrl" width="120"></el-table-column>
        <el-table-column label="ingredients" width="90">
            <template slot-scope="scope">
                {{scope.row.ingredients ? "SI" : "NO"}}
            </template>
        </el-table-column>
        <el-table-column label="procedureSteps" width="120">
            <template slot-scope="scope">
                {{scope.row.procedureSteps ? "SI" : "NO"}}
            </template>
        </el-table-column>
        <el-table-column label="fullHtml" width="120">
            <template slot-scope="scope">
                {{scope.row.fullHtml ? "SI" : "NO"}}
            </template>
        </el-table-column>
        <el-table-column label="genericIntro" width="90">
            <template slot-scope="scope">
              {{hasCustomIntro(scope.row.introText) ? "SI" : "NO"}}
            </template>
        </el-table-column>

        <el-table-column width="70">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="fas fa-edit"
              v-on:click="editRecipe(scope.row)"
            ></el-button>
             <el-button
              type="text"
              size="small"
              icon="fas fa-external-link-square-alt"
              v-on:click="goToRecipe(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify"; // eslint-disable-line no-unused-vars
import router from "@/router";

export default {
  name: "RecipesAdmin",
  beforeCreate() {
    
  },
  created: function () {
    var noIngredientsCookie = this.$cookies.get("noIngredients");
    if (noIngredientsCookie == "true") {
      this.noIngredients = true;
    } else {
      this.noIngredients = false;
    }
    var noProcedureCookie = this.$cookies.get("noProcedure");
    if (noProcedureCookie == "true") {
      this.noProcedure = true;
    } else {
      this.noProcedure = false;
    }
    var onYoutubeCookie = this.$cookies.get("onYoutube");
    if (onYoutubeCookie == "true") {
      this.onYoutube = true;
    } else {
      this.onYoutube = false;
    }
    var noFullHtmlCookie = this.$cookies.get("noFullHtml");
    if (noFullHtmlCookie == "true") {
      this.noFullHtml = true;
    } else {
      this.noFullHtml = false;
    }

    this.$store.dispatch("main/retrieveAllRecipes").then((response) => {
      this.recipesFull = response;
      this.updateRecipesList();
    });
  },
  data() {
    return {
      recipesFull: null,
      recipes: null,
      noIngredients: false,
      noProcedure: false,
      onYoutube: false,
      noFullHtml: false
    };
  },
  methods: {
    goToCategories() {
      router.push("/categories");
    },
    goToArticles() {
      router.push("/articles");
    },
    editRecipe(entry) {
      router.push("/recipe/" + entry.id);
    },
    goToRecipe(entry) {
      location.href = 'http://localhost:8000/receta/' + entry.pageUrl;
    },
    updateRecipesList() {
      if (!this.recipesFull) {
        return;
      }

      var final = [];
      for (var i = 0; i < this.recipesFull.length; i++) {
        var current = this.recipesFull[i];
        if (this.noIngredients) {
          if (!current.ingredients) {
            if (!this.onYoutube || (this.onYoutube && current.youtubeUrl)) {
              final.push(current);
            }
          }
        } else if (this.noProcedure) {
           if (!current.procedureSteps) {
            if (!this.onYoutube || (this.onYoutube && current.youtubeUrl)) {
              final.push(current);
            }
          }
        } else if (this.noFullHtml) {
           if (!current.fullHtml) {
            if (!this.onYoutube || (this.onYoutube && current.youtubeUrl)) {
              final.push(current);
            }
          }
        } else {
          if (!this.onYoutube || (this.onYoutube && current.youtubeUrl)) {
            final.push(current);
          }
        }
      }

      this.recipes = final;
    },
    hasCustomIntro(intro) {
      if (intro) {
        return intro.toLowerCase().startsWith('aprende como');
      }
      return true;
    }
  },
  watch: {
    noIngredients() {
      this.$cookies.set("noIngredients", this.noIngredients);
      this.updateRecipesList();
    },
    noProcedure() {
      this.$cookies.set("noProcedure", this.noProcedure);
      this.updateRecipesList();
    },
    onYoutube() {
      this.$cookies.set("onYoutube", this.onYoutube);
      this.updateRecipesList();
    },
    noFullHtml() {
      this.$cookies.set("noFullHtml", this.noFullHtml);
      this.updateRecipesList();
    }
  }
};
</script>

<style>
  .searchFilters {
    margin-bottom: 10px;
  }
</style>
