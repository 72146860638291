<template>
  <div>
        <router-link to='/categories'>Volver</router-link>
        <button @click="next()">Next</button>
        <button @click="prev()">Prev</button>

        <div class="categoryFieldDiv">
            <span class="fieldLabel">Id</span>
            <span class="fieldValue">{{category.id}}</span>
        </div>

        <div class="categoryFieldDiv">
            <span class="fieldLabel">name</span>
            <span class="fieldValue"><el-input v-model="category.name" type="text"/></span>
        </div>

        <div class="categoryFieldDiv">
            <span class="fieldLabel">title</span>
            <span class="fieldValue"><el-input v-model="category.title" type="text"/></span>
        </div>

        <div class="categoryFieldDiv">
            <span class="fieldLabel">pageUrl</span>
            <span class="fieldValue"><el-input v-model="category.pageUrl" type="text"/></span>
        </div>

        <div class="categoryFieldDiv">
            <span class="fieldLabel">shortText</span>
            <span class="fieldValue"><el-input type="textarea" :autosize="{ minRows: 5, maxRows: 15}" v-model="category.shortText"/></span>
        </div>

        <div class="categoryFieldDiv">
            <span class="fieldLabel">text</span>
            <span class="fieldValue"><el-input type="textarea" :autosize="{ minRows: 15, maxRows: 25}" v-model="category.text"/></span>
        </div>

        <button @click="save()">Guardar</button>
        <button @click="next()">Next</button>
        <button @click="prev()">Prev</button>
        {{savedStatus}}
  </div>
</template>

<script>
import router from "@/router";

export default {
    name: "CategoryEdition",
    props: [
        'id',
    ],
    beforeCreate() {},
    created: function () {
       this.loadCategory();
    },
    data() {
        return {
            category: null,
            savedStatus: null
        };
    },
    methods: {
        loadCategory() {
            this.savedStatus = "";
            this.$store.dispatch("main/retrieveCategoryById", this.id).then((response) => {
                this.category = response;
            });
        },
        save() {
            this.savedStatus = "";
            this.$store.dispatch("main/saveCategory", this.category).then((response) => {
                this.savedStatus = response ? "GUARDADO" : "ERROR";
            });
        },
        saveAndBack() {
            this.savedStatus = "";
             this.$store.dispatch("main/saveCategory", this.category).then((response) => {
                this.savedStatus = response ? "GUARDADO" : "ERROR";
                if (response) {
                    router.push("/");
                }
            });
        },
        next() {
            router.push("/category/" + (parseInt(this.id) + 1));
        },
        prev() {
            router.push("/category/" + (parseInt(this.id) - 1));
        }
    },
    watch: {
        id() {
            this.loadCategory();
        }
    }
};
</script>

<style>
.categoryFieldDiv {
    display: flex;
    flex-direction: column;
}
.categoryFieldDiv .fieldLabel {
    font-weight: bold;
    margin-top: 5px;
}
.categoryFieldDiv .el-textarea__inner {
    min-width: 1000px;
}
.categoryFieldDiv .el-input__inner {
    min-width: 1000px;
}
</style>