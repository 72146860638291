import Vue from 'vue'
import Vuex from 'vuex'
import pathify from "vuex-pathify";
import main from '@/store/modules/main'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [
    pathify.plugin
  ],
  modules: {
    main,
  }
})