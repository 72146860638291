const axios = require("axios");
import { config } from "assets/config.js";
import store from "@/store";
import router from "@/router";

function getAPI(isLaravel, endpoint, params, throwError) {
  params = params || {};
  throwError = throwError || false;
  var api = null;
  if (isLaravel) {
    api = config.laravelrestApiURL;
    if (!PRODUCTION) api = config.devLaravelRestApiURL; // eslint-disable-line
  } else {
    api = config.restApiURL;
    if (!PRODUCTION) api = config.devRestApiURL; // eslint-disable-line
  }
  var url = api + endpoint;
  return axios
    .get(url, {
      crossdomain: true,
      params
    })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      if (!throwError && error.response && error.response.status == 401) {
        //Stop other "threads":
        window.stop();
        document.execCommand("Stop");

        store.set("global/redirectTo", router.currentRoute);
        router.push("/login");
      } else throw error;
    });
}

function postAPI(isLaravel, endpoint, data, throwError, headers) {
  data = data || {};
  throwError = throwError || false;
  var api = null;
  if (isLaravel) {
    api = config.laravelrestApiURL;
    if (!PRODUCTION) api = config.devLaravelRestApiURL; // eslint-disable-line
  } else {
    api = config.restApiURL;
    if (!PRODUCTION) api = config.devRestApiURL; // eslint-disable-line
  }
  var url = api + endpoint;
  var configParam = { crossdomain: true };
  if (headers) {
    configParam.headers = headers;
  }
  return axios
    .post(url, data, configParam)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      if (!throwError && error.response && error.response.status == 401) {
        //Stop other "threads":
        window.stop();
        document.execCommand("Stop");

        store.set("global/redirectTo", router.currentRoute);
        router.push("/login");
      } else throw error;
    });
}

function getDownloadAPI(endpoint, params, throwError) {
  params = params || {};
  throwError = throwError || false;
  var api = config.restApiURL;
  if (!PRODUCTION) api = config.devRestApiURL; // eslint-disable-line
  var url = api + endpoint;
  return axios
    .get(url, {
      crossdomain: true,
      responseType : 'blob',
      params
    })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      if (!throwError && error.response && error.response.status == 401) {
        //Stop other "threads":
        window.stop();
        document.execCommand("Stop");

        store.set("global/redirectTo", router.currentRoute);
        router.push("/login");
      } else throw error;
    });
}

export { getAPI, postAPI, getDownloadAPI };
