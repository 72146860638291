<template>
  <div id="app" class="table" style="background-color: #e5e9ec">
  
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify"; // eslint-disable-line no-unused-vars

export default {
  name: "Home",
  created: function () {
   
  },
  data() {
    return {

    };
  },
  methods: {
 
  },
};
</script>

<style>

</style>
