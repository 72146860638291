<template>
  <div id="app" class="table" style="background-color: #e5e9ec">
    <div class="searchFilters">
      <button @click="goToRecipes()">Recipes</button>
    </div>
    
    <el-table v-bind:data="articles" stripe class="table table-striped">
        <el-table-column label="id" prop="id" width="30"></el-table-column>
        <el-table-column label="name" prop="name" width="600"></el-table-column>
        <el-table-column label="introText" width="70">
            <template slot-scope="scope">
                {{scope.row.introText ? "SI" : "NO"}}
            </template>
        </el-table-column>
        <el-table-column label="content" width="70">
            <template slot-scope="scope">
                {{scope.row.content ? "SI" : "NO"}}
            </template>
        </el-table-column>

        <el-table-column width="50">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="fas fa-edit"
              v-on:click="editArticle(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify"; // eslint-disable-line no-unused-vars
import router from "@/router";

export default {
  name: "Home",
  beforeCreate() {
    
  },
  created: function () {
    this.$store.dispatch("main/retrieveAllArticles").then((response) => {
      this.articles = response;
    });
  },
  data() {
    return {
      articles: null,
    };
  },
  methods: {
    goToRecipes() {
      router.push("/");
    },
    editArticle(entry) {
      router.push("/article/" + entry.id);
    },
  },
};
</script>

<style>
  .searchFilters {
    margin-bottom: 10px;
  }
</style>
