import { make } from "vuex-pathify";
import { getAPI, postAPI } from "@/store/restAPI.js";

// initial state
const state = {
  
};

// actions
const actions = {
  retrieveAllRecipes() {
    return getAPI(false, "retrieveAllRecipes")
    .then(response => {
      return response;
    })
  },
  retrieveRecipeById({ commit }, payload) {
    commit;
    return getAPI(false, "retrieveRecipeById/" + payload)
    .then(response => {
      return response;
    })
  },
  saveRecipe({ commit }, payload) {
    commit;
    return postAPI(false, "saveRecipe", payload)
    .then(response => {
      return response;
    })
  },
  retrieveAllCategories() {
    return getAPI(false, "retrieveAllCategories")
    .then(response => {
      return response;
    })
  },
  retrieveCategoryById({ commit }, payload) {
    commit;
    return getAPI(false, "retrieveCategoryById/" + payload)
    .then(response => {
      return response;
    })
  },
  saveCategory({ commit }, payload) {
    commit;
    return postAPI(false, "saveCategory", payload)
    .then(response => {
      return response;
    })
  },
  retrieveAllArticles() {
    return getAPI(false, "retrieveAllArticles")
    .then(response => {
      return response;
    })
  },
  retrieveArticleById({ commit }, payload) {
    commit;
    return getAPI(false, "retrieveArticleById/" + payload)
    .then(response => {
      return response;
    })
  },
  saveArticle({ commit }, payload) {
    commit;
    return postAPI(false, "saveArticle", payload)
    .then(response => {
      return response;
    })
  },
  login({ commit }, payload) {
    commit;
    return postAPI(true, "authentication/login", payload)
    .then(response => {
      return response;
    })
  },
  retrieveAllDataEntryRecipes({ commit }) {
    commit;
    return getAPI(true, "recipes/retrieveAllDataEntryRecipes")
    .then(response => {
      return response;
    })
  },
  retrieveDataEntryRecipesById({ commit }, payload) {
    commit;
    return getAPI(true, "recipes/retrieveDataEntryRecipesById/" + payload)
    .then(response => {
      return response;
    })
  },
  saveDataEntryRecipe({ commit }, payload) {
    commit;
    return postAPI(true, "recipes/saveDataEntryRecipe", payload)
    .then(response => {
      return response;
    })
  },
  getDataEntryStats({ commit }) {
    commit;
    return getAPI(true, "recipes/getDataEntryStats")
    .then(response => {
      return response;
    })
  },
};

// mutations
const mutations = {
  ...make.mutations(state),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};