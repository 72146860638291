import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import BootstrapVue from 'bootstrap-vue'
import 'element-ui/lib/theme-chalk/display.css';
import GlobalEvents from 'vue-global-events'
import ToggleButton from 'vue-js-toggle-button'
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets'
import VueCookies from 'vue-cookies'

//import the theme
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
var VueCookie = require('vue-cookie');
Vue.use(VueCookie);
Vue.directive("el-focus", {
  inserted: function (el) {
    el.children[0].focus();
  }
});
require('es6-promise').Promise;
require('es6-promise').polyfill();
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.component('GlobalEvents', GlobalEvents);
Vue.use(require('vue-moment'));
Vue.use(require('vue-chartist'))
Vue.use(ToggleButton)
Vue.use(VueFusionCharts, FusionCharts, Widgets, FusionTheme)
Vue.use(VueCookies)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
