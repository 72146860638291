<template>
  <div id="app" class="table" style="background-color: #e5e9ec">
    <div class="loginMainBox">
      <div class="loginFieldDiv">
          <span class="fieldLabel">Usuario</span>
          <span class="fieldValue"><el-input v-model="user" type="text"/></span>
      </div>
        <div class="loginFieldDiv">
          <span class="fieldLabel">Contraseña</span>
          <span class="fieldValue"><el-input v-model="password" type="password"/></span>
      </div>

      <button class="loginButton" @click="login()">Entrar</button>
    </div>
  </div>
</template>

<script>
import VueCookie from 'vue-cookies'

export default {
  name: "Login",
  created: function () {
   
  },
  data() {
    return {
      user: null,
      password: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("keypress", this.keypressCallback);
    })
  },
  beforeDestroy() { 
      window.removeEventListener('keypress', this.keypressCallback);
  },
  methods: {
    login() {
      var request = {
        user: this.user,
        password: this.password,
      };

      this.$store.dispatch("main/login", request).then((response) => {
        if (response == "OK") {
          VueCookie.set('authentication', '9Zj1Se6c5oGdmqCVrlrQ', { expires: '7d' });
          this.$router.push({ name: "RecipesDataEntryAdmin" });
        } else {
          alert("Contraseña o usuario incorrecto");
        }
      });
    },
    keypressCallback(e) {
      console.log(e);  // eslint-disable-line
      if (e.key == "Enter") {
          this.login();
      }
    }
  },
};
</script>

<style>
  .loginMainBox {
    text-align: center;
    margin-top: 300px;
  }
  .loginFieldDiv {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin: auto;
      text-align: left;
  }
  .loginFieldDiv .fieldLabel {
      font-weight: bold;
      margin-top: 5px;
  }
  .loginFieldDiv .el-input__inner {
      width: 100%;
  }
  .loginButton {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
</style>