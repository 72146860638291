<template>
  <div>
        <router-link :to="{name: 'RecipesDataEntryAdmin'}">Volver</router-link>
        <button class="recipeDataEntryButton" @click="next()">Siguiente</button>
        <button class="recipeDataEntryButton" @click="prev()">Anterior</button>

        <div class="recipeDataEntryFieldDiv">
            <span class="fieldLabel">Receta</span>
            <span class="fieldValue">{{recipe.recipeId}} - {{recipe.name}}</span>
        </div>

        <iframe v-if="recipe.youtubeUrl" width="840" height="472" :src="'https://www.youtube.com/embed/' + recipe.youtubeUrl"></iframe>
        <div v-if="!recipe.youtubeUrl" style="font-size: 20px;">No Subido a Youtube</div>

        <div class="recipeDataEntryHelpMainDiv">
            <div class="recipeDataEntryHelpEntry">Con el video parado usar la tecla 'punto' y la tecla 'coma' para avanzar o retroceder de a uno.</div>
            <div class="recipeDataEntryHelpEntry">Hacer "Copiar Información de Depuración" o "Copy Debug Info", pegar en las cajas de abajo.</div>
        </div>

        <div class="recipeDataEntryStepDiv" v-for="step in recipe.steps" :key="step.stepNumber">
            <span class="stepDescription">{{step.stepNumber + 1}}. {{step.description}}</span>
            <span class="stepFrameValue"><el-input v-model="step.frame" type="text"/></span>
        </div>

        <button class="recipeDataEntryButton" @click="saveAndBack()">Guardar y Volver</button>
        <button class="recipeDataEntryButton" @click="saveAndNext()">Guardar y Siguiente</button>
        <button class="recipeDataEntryButton" @click="save()">Guardar</button>
        <button class="recipeDataEntryButton" @click="next()">Siguiente</button>
        <button class="recipeDataEntryButton" @click="prev()">Anterior</button>
        {{savedStatus}}
  </div>
</template>

<script>
import router from "@/router";

export default {
    name: "RecipeDataEntryEdition",
    props: [
        'id',
    ],
    beforeCreate() {},
    created: function () {
       this.loadRecipe();
    },
    data() {
        return {
            recipe: null,
            savedStatus: null
        };
    },
    methods: {
        loadRecipe() {
            this.savedStatus = "";
            this.$store.dispatch("main/retrieveDataEntryRecipesById", this.id).then((response) => {
                this.recipe = response;
            });
        },
        save() {
            this.savedStatus = "";
            this.$store.dispatch("main/saveDataEntryRecipe", this.recipe).then((response) => {
                this.savedStatus = response == "OK" ? "GUARDADO" : "ERROR";
            }).catch(() => {
                this.savedStatus = "ERROR";
            });
        },
        saveAndBack() {
            this.savedStatus = "";
             this.$store.dispatch("main/saveDataEntryRecipe", this.recipe).then((response) => {
                this.savedStatus = response == "OK" ? "GUARDADO" : "ERROR";
                if (response == "OK") {
                    router.push("/recipesDataEntryAdmin");
                }
            }).catch(() => {
                this.savedStatus = "ERROR";
            });
        },
        saveAndNext() {
            this.savedStatus = "";
             this.$store.dispatch("main/saveDataEntryRecipe", this.recipe).then((response) => {
                this.savedStatus = response == "OK" ? "GUARDADO" : "ERROR";
                if (response == "OK") {
                    this.next();
                }
            }).catch(() => {
                this.savedStatus = "ERROR";
            });
        },
        next() {
            router.push("/recipeDataEntryEdition/" + (parseInt(this.id) + 1));
        },
        prev() {
            router.push("/recipeDataEntryEdition/" + (parseInt(this.id) - 1));
        }
    },
    watch: {
        id() {
            this.loadRecipe();
        },
        recipe: {
            deep: true,
            handler() {
                for (var i = 0; i < this.recipe.steps.length; i++) {
                    var frame = this.recipe.steps[i].frame;
                    if (frame && frame.includes("cmt")) {
                        this.recipe.steps[i].frame = JSON.parse(this.recipe.steps[i].frame).cmt;
                    }
                }
            }
        }
    }
};
</script>

<style>
.recipeDataEntryFieldDiv {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}
.recipeDataEntryFieldDiv .fieldLabel {
    font-weight: bold;
    margin-top: 5px;
}
.recipeDataEntryFieldDiv .el-textarea__inner {
    min-width: 1000px;
}
.recipeDataEntryFieldDiv .el-input__inner {
    min-width: 1000px;
}
.recipeDataEntryStepDiv {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 12px;
}
.recipeDataEntryStepDiv .stepDescription {
    width: 800px;
}
.recipeDataEntryStepDiv .stepFrameValue {
    margin-top: 3px;
}
.recipeDataEntryStepDiv .el-input__inner {
   width: 100px;
}
.recipeDataEntryButton {
    margin-right: 10px;
}
.recipeDataEntryHelp {
    margin-top: 2px;
    margin-bottom: 2px;
}
.recipeDataEntryHelpMainDiv {
    font-size: 17px;
    margin-bottom: 10px;
}
</style>